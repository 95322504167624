import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';
import img4 from '../images/img4.png';
import img5 from '../images/img5.png';
import img6 from '../images/img6.png';



export const ProductsData1 = [{
    name: 'Iphone 14',
    price: '1000.00',
    img: img1,
    
},
{
    name: 'Men hands watch',
    price: '150.00',
    img: img2,
    
},
{
    name: 'Table wing chair',
    price: '180.00',
    img: img3,
    
}]

export const ProductsData2 = [
{
    name: 'Man jacket',
    price: '50.00',
    img: img4,
    
},
{
    name: 'Ladies hand bag',
    price: '35.00',
    img: img5,
    
},
{
    name: 'Book of Technology',
    price: '65.00',
    img: img6,
    
},
]







